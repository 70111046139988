<template>
  <div class="FTEPaymentDetailBody">
    <PocCardTable class="FTEPaymentDetail">
<!--      <template v-slot:headerTitle>FET Repo</template>-->
<!--      <template v-slot:headerGroup>-->
<!--        <el-button @click="search" type="primary">查询</el-button>-->
<!--        <el-button @click="download" type="primary">下载</el-button>-->
<!--        <el-button @click="goback">返回</el-button>-->
<!--      </template>-->
      <template>
        <div class="flexBetw opeartionBox">
          <div class="pcth-title-left">FTE Repo</div>
          <div>
            <el-button @click="search" type="primary">查询</el-button>
            <el-button @click="download" type="primary">下载</el-button>
            <el-button @click="goback">返回</el-button>
          </div>
        </div>
      </template>
      <template>
        <el-form
          :inline="true"
          label-position="top"
          :model="formInline"
          class="personnelRequisitionForm"
        >
          <el-form-item label="HFM Code">
            <el-select
              v-model="formInline.hfmCode"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in hfmCodes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Bussiness Unit">
            <el-select v-model="formInline.bu" clearable filterable placeholder="请选择">
              <el-option
                v-for="item in bus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="社保所在城市">
            <el-select
              v-model="formInline.socialInsuloaction"
              clearable
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in socialInsuloactions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="CS ID" label-position="top">
            <el-input placeholder="" v-model="formInline.csId"> </el-input>
          </el-form-item>

          <el-form-item label="名字" label-position="top">
            <el-input placeholder="" v-model="formInline.cnName"> </el-input>
          </el-form-item>
        </el-form>
      </template>
    </PocCardTable>
    <PocCardTable style="margin-top: 20px">
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        size="large"
        :max-height="maxHeight"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        highlight-current-row
      >
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>
      </PocTable>
    </PocCardTable>
  </div>
</template>

<script>
import { FTEPaymentDetailTableColumn, FTEPaymentNewDetailTableColumn } from '@/views/payroll/TableColumn'
import {
  getPayrollFTEDetailList,
  getPayrollFTEHfmCodeList,
  getPayrollBuList,
  getPayrollSocialInsuloactionList
} from '@/api/payroll'
import {fteExport} from '@/api/payrollReport'

import PocSlider from '@/components/poc-slider/poc-slider'
const { Message } = $PCommon
export default {
  name: 'actData',
  mixins: [$PCommon.TableMixin],
  components: {
    PocSlider
  },

  data() {
    return {
      loading: false,
      columnConfig: [],
      tableData: [],
      pageConfig: {
        total: 1,
        pageNum: 1
      },
      formInline: {
        hfmCode: '',
        bu: '',
        socialInsuloaction: '',
        csId: '',
        cnName: '',
        pageSize: 10,
        pageNum: 1,
        periodUploadRecord: ''
      },

      hfmCodes: [],
      bus: [],
      socialInsuloactions: [],
      targetObj: '',
      toObj: '',
      periodDialogVisible: false,
      newPeriod: '',
      formStatus: false,
      periodRange: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 330
    this.formInline.periodUploadRecord = this.$route.query.id
    if(this.$route.query.period) {
      let period = parseInt(this.$route.query.period);
      period >= 202306 ? this.columnConfig = FTEPaymentNewDetailTableColumn : this.columnConfig = FTEPaymentDetailTableColumn
    }
    this.queryApi()
    this.getFormLineData()
  },
  mounted() {},
  methods: {
    async getFormLineData() {
      getPayrollFTEHfmCodeList().then((res) => {
        this.hfmCodes = res.data
      }),
        getPayrollBuList().then((res) => {
          this.bus = res.data
        }),
        getPayrollSocialInsuloactionList().then((res) => {
          this.socialInsuloactions = res.data
        })
    },

    search() {
      getPayrollFTEDetailList(this.formInline).then((res) => {
        this.tableData = res.data.list
        this.pageConfig = res.data
      })
    },

    queryApi() {
      this.search()
    },

    pageSizeChange(val) {
      this.formInline.pageSize = val
      this.queryApi()
    },

    pageChange(val) {
      this.formInline.pageNum = val
      this.queryApi()
    },

    download() {
      let params = {
        ...this.formInline
      }
      if(this.$route.query.period) {
        params.period = this.$route.query.period;
      }
      fteExport(params)
    },

    goback() {
      this.$router.push({
        path: '/pcTracker/payroll',
        query: {
          id: 'FTEPayment',
          title: 'FTEPayment'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.FTEPaymentDetailBody {
  overflow-x: hidden;
  .flexBetw {
    display: flex;
    justify-content: space-between;
  }
  ::v-deep .el-form .el-form-item {
    margin: 16px 16px 0 0 !important;
  }
  .FTEPaymentDetail {
    .topTitle {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
